module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"3714f86f-f5cb-4a95-9469-c01947429ea0","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":92204172,"webvisor":true,"afterBody":false,"clickmap":true,"trackLinks":true,"accurateTrackBounce":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NR8B6M80CJ"],"pluginConfig":{"head":true}},
    }]
